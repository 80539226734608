/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.leaflet-container {
    height: 100%;
    width: 100%;
  }

[data-aos="grow-animation-row"]{
    width: 0%;
}
  
[data-aos="grow-animation-row"].aos-animate{
    width: 100%;
}

[data-aos="grow-animation-column"]{
    height: 0%;
}
  
[data-aos="grow-animation-column"].aos-animate{
    height: 80%;
}

@media (max-width: 599px) {
[data-aos="custom-fade"] {
    transform: translate3d(-100px,0,0);
    opacity: 0;
    transition-property: opacity, transform;
    transition-timing-function: ease;
    transition-duration: 0.4s;
    &.aos-animate {
      transform: translateZ(0);
      opacity: 1;
    }
  }
}

@media (min-width: 600px) {
    [data-aos="custom-fade"] {
        transform: translate3d(0,100px,0);
        opacity: 0;
        transition-property: opacity, transform;
        transition-timing-function: ease;
        transition-duration: 0.4s;
        &.aos-animate {
          transform: translateZ(0);
          opacity: 1;
        }
      }
    }

.loader {
    width: 60px;
    aspect-ratio: 4;
    --_g: no-repeat radial-gradient(circle closest-side,#ffffff 90%,#0000);
    background: 
      var(--_g) 0%   50%,
      var(--_g) 50%  50%,
      var(--_g) 100% 50%;
    background-size: calc(100%/3) 100%;
    animation: l7 1s infinite linear;
  }
  @keyframes l7 {
      33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
      50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
      66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
  }

